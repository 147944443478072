import React, { FC, useState, useEffect, useMemo } from 'react';
import {
  Product,
  Color,
  FlexRow,
  H2,
  H1,
  Image,
  Paragraph,
  Svg,
  H3,
  H4,
  useColors,
  useQuery,
  getColorNameByBgColorHex,
} from '@thisisdevelopment/akzonobel-core';
import {
  Header,
  Content,
  Background,
  ArticleWrapper,
  ChooseButton,
  ProductDescription,
  ColorDescription,
  ArticleHeader,
  ColorsWrapper,
  ColorsHeader,
  ColorPickerWrapper,
  ArticleContentWrapper,
  TryDifferentLightsCTA,
} from './styled';
import CmsImage from 'components/CmsImage';
import Article from 'components/Article';
import ColorPicker from 'components/ColorPicker';
import OrderFormModal from 'components/OrderFormModal';
import { useTheme } from 'styled-components';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { hasColors } from 'util/product';
import CustomIcon from 'components/CustomIcon';

type PropType = {
  mode: 'Product' | 'Color';
  imageStatus?: 'loading' | 'success' | 'error';
  enableChangeLights?: boolean;
  product?: Product;
  color?: Color;
  colorProducts?: Product[];
  backgroundImage?: string | number;
  openColors?: boolean;
};

const ProductColor: FC<PropType> = ({
  product,
  color,
  backgroundImage,
  mode,
  imageStatus,
  enableChangeLights,
  colorProducts,
  openColors = false,
}: PropType) => {
  const theme = useTheme();
  const { colors, fetchColors } = useColors();
  const [isArticleOpen, setIsArticleOpen] = useState<boolean>(mode === 'Product');
  const [isColorsOpen, setIsColorsOpen] = useState<boolean>(openColors);
  const isTablet = useMediaQuery(theme.mediaQuery.tablet);
  const isMobile = useMediaQuery(theme.mediaQuery.mobile);
  const query = useQuery();
  const [pickAColour, setPickAColor] = useState(query.get('pickAColour'));
  const [orderModalIsOpen, setOrderModalIsOpen]: [boolean, Function] = useState(false);

  const textColor = useMemo(
    () => (mode === 'Color' && color ? getColorNameByBgColorHex(color.rgb) : theme.colors.black),
    [color, mode, theme.colors.black]
  );

  useEffect(() => {
    fetchColors('', { flip90AntiClockwise: true });
  }, [fetchColors]);

  useEffect(() => {
    if (color && pickAColour !== 'true') {
      setIsColorsOpen(false);
    } else {
      setPickAColor('false');
    }
  }, [color, setIsColorsOpen]);

  const toggleColors = () => {
    setIsColorsOpen((isOpen) => !isOpen);
    isTablet && window.scroll({ top: 0 });
  };

  const goToSwitchLights = () => {
    const element = document.getElementById('try-different-lights');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const TryDifferentLightsButton = mode === 'Color' &&
    color &&
    imageStatus === 'success' &&
    enableChangeLights && (
      <TryDifferentLightsCTA onClick={() => goToSwitchLights()}>
        <CustomIcon name="LightBulb" />
        Try different light sources
      </TryDifferentLightsCTA>
    );

  return (
    <>
      {orderModalIsOpen && (
        <OrderFormModal
          title={'Order a Colour Card'}
          subTitle={'Fill in your details to order a colour card'}
          successText={'Thank you!'}
          onClose={() => setOrderModalIsOpen(false)}
          width="500px"
        />
      )}
      <Header style={{ color: textColor, flexDirection: 'row-reverse' }}>
        <FlexRow alignItems="center">
          {mode === 'Product' && product && hasColors(product) && (
            <>
              {color ? <H2>Choose another colour</H2> : <H2>Choose a colour</H2>}
              <ChooseButton onClick={toggleColors}>+</ChooseButton>
            </>
          )}
          {mode === 'Color' && (
            <>
              {color ? <H2>Choose another colour</H2> : <H2>Choose a colour</H2>}
              <ChooseButton onClick={toggleColors}>+</ChooseButton>
            </>
          )}
        </FlexRow>
        {mode === 'Product' && product && <H1>{product.name}</H1>}
        {mode === 'Color' && color && (
          <H1>
            <span>You selected: </span>
            <strong>{color.defaultColorName}</strong>
          </H1>
        )}
      </Header>
      {isMobile && TryDifferentLightsButton}
      <Content>
        <Background>
          {/* TODO: fix typing, or better, should be separate components from the start */}
          {mode === 'Product' && color && product && hasColors(product) ? (
            <Image src={`${backgroundImage}`} />
          ) : (
            backgroundImage && <CmsImage id={+backgroundImage} />
          )}
          {mode === 'Color' && color && <Image src={color.inspirationalImages?.[4].url} />}
        </Background>
        {mode === 'Product' && product && (
          <ProductDescription>
            {product?.shortDescription && <H2>Product description</H2>}
            <Paragraph>{product?.shortDescription}</Paragraph>
          </ProductDescription>
        )}
        {mode === 'Color' && color && (
          <ColorDescription textColor={textColor}>
            <Paragraph>{color.description}</Paragraph>
          </ColorDescription>
        )}
        <ArticleWrapper collapsed={!isArticleOpen}>
          <ArticleHeader onClick={() => setIsArticleOpen((isOpen) => !isOpen)}>
            {product && hasColors(product) ? (
              <H2>Ready to buy this colour?</H2>
            ) : (
              <H2>Ready to buy this product?</H2>
            )}
            {isArticleOpen ? <Svg.ExpandMore /> : <Svg.ExpandLess />}
          </ArticleHeader>
          <ArticleContentWrapper>
            <Article
              product={product}
              color={color}
              colorProducts={colorProducts}
              mode={mode}
              setOrderModalIsOpen={setOrderModalIsOpen}
              onChangeColor={toggleColors}
            />
          </ArticleContentWrapper>
        </ArticleWrapper>

        {mode === 'Product' && product && hasColors(product) && (
          <ColorsWrapper collapsed={!isColorsOpen}>
            <ColorsHeader onClick={toggleColors}>
              {isColorsOpen ? (
                <>
                  <H4>+</H4>
                  <H2>Close</H2>
                </>
              ) : (
                <>
                  <H3>+</H3>
                  <H2>Colours</H2>{' '}
                </>
              )}
            </ColorsHeader>
            <ColorPickerWrapper>
              {isColorsOpen && <ColorPicker mode={mode} colors={colors} />}
            </ColorPickerWrapper>
          </ColorsWrapper>
        )}

        {mode === 'Color' && (
          <ColorsWrapper collapsed={!isColorsOpen}>
            <ColorsHeader onClick={toggleColors}>
              {isColorsOpen ? (
                <>
                  <H4>+</H4>
                  <H2>Close</H2>
                </>
              ) : (
                <>
                  <H3>+</H3>
                  <H2>Colours</H2>{' '}
                </>
              )}
            </ColorsHeader>
            <ColorPickerWrapper>
              {isColorsOpen && <ColorPicker mode={mode} colors={colors} />}
            </ColorPickerWrapper>
          </ColorsWrapper>
        )}
        {!isMobile && TryDifferentLightsButton}
      </Content>
    </>
  );
};

export default ProductColor;

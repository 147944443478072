import { Sun } from './icons/Sun';
import { Moon } from './icons/Moon';
import { LightBulb } from './icons/LightBulb';
import { Fire } from './icons/Fire';
import { Direction } from './icons/Direction';
import { Group } from './icons/Group';
import { Sunrise } from './icons/Sunrise';
import { Sunset } from './icons/Sunset';

const icons = {
  Sun,
  LightBulb,
  Fire,
  Moon,
  Direction,
  Group,
  Sunrise,
  Sunset,
} as const;

export interface CustomIconProps extends React.SVGProps<SVGSVGElement> {
  name: keyof typeof icons;
}

const CustomIcon = (props: CustomIconProps) => icons[props.name](props) || null;

export default CustomIcon;
